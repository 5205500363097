import * as React from 'react';

const PostPage = () => {
  return (
    <div>
      <h1>POSTS PAGE</h1>
    </div>
  );
};

export default PostPage;
